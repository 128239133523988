:root {

    --filter-shadow-low:  drop-shadow(0 0  8px hsl(0 0% 0% / 20%));
    --filter-shadow-mid:  drop-shadow(0 0 12px hsl(0 0% 0% / 20%));
    --filter-shadow-high: drop-shadow(0 0 16px hsl(0 0% 0% / 20%));

    --box-shadow-low:   0 0  8px hsl(0 0% 0% / 20%);
    --box-shadow-mid:   0 0 12px hsl(0 0% 0% / 20%);
    --box-shadow-high:  0 0 16px hsl(0 0% 0% / 20%);

}
