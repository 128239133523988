@import 'node_modules/modern-normalize/modern-normalize.css';

@import 'colours.scss';
@import 'typography.scss';
@import 'layers.scss';
@import 'shadows.scss';
@import 'borders.scss';
@import 'spacing.scss';
@import 'transitions.scss';
@import 'events-button.scss';

:root {

    //can probably just be deleted, but keeping as a record incase we reuse the theming
    [data-site='materials-energy-expo'] {
        --colour-text-body: #{$mee-colour-4};
        --colour-text-bright: white;
        --colour-text-dull: #{$mee-colour-4};
        --colour-text-mid: #{$mee-colour-4};
        --colour-text-accent: #{$mee-colour-1};
        --colour-text-accent-dark: #{$ami-dark-blue};
        --colour-text-decoration: #{$mee-colour-5};
        --colour-background-accent: #{$mee-colour-2};
        --colour-background-accent-dark: #{$mee-colour-3};
    }

    --colour-button-accept: hsl(48, 100%, 60%);
    --colour-button-decline: hsl(0, 64%, 46%);

    --colour-border-mid: hsl(0, 0%, 80%);

    //
    // Backgrounds
    // To be replaced by themes
    //

    [data-background='Bright'] {
        background: var(--colour-background-primary) !important;
    }

    [data-background='Dull'] {
        background: var(--colour-background-secondary) !important;
    }

    [data-background='Accent'] {
        background: var(--colour-background-accent-primary) !important;
    }

    [data-background='MEE_Colour_1'] {
        background: #{$mee-colour-1} !important;
        --colour-background-accent-primary: #{$mee-colour-2} !important;
        --colour-text-primary: #{$mee-colour-5} !important;
        --colour-text-tertiary: #{$mee-colour-5} !important;
        --colour-text-accent-primary: #{$mee-colour-5} !important;
    }

    [data-background='MEE_Colour_2'] {
        background: #{$mee-colour-2} !important;
        --colour-background-accent-primary: #{$mee-colour-5} !important;
        --colour-text-primary: #{$mee-colour-5} !important;
        --colour-text-tertiary: #{$mee-colour-5} !important;
        --colour-text-accent-primary: #{$mee-colour-5} !important;
    }

    [data-background='MEE_Colour_3'] {
        background: #{$mee-colour-3} !important;
        --colour-background-accent-primary: #{$mee-colour-2} !important;
        --colour-text-primary: #{$mee-colour-5} !important;
        --colour-text-tertiary: #{$mee-colour-5} !important;
        --colour-text-accent-primary: #{$mee-colour-5} !important;
    }

    [data-background='MEE_Colour_5'] {
        background: #{$mee-colour-5} !important;
        --colour-background-accent-primary: #{$mee-colour-2} !important;
        --colour-text-primary: #{$mee-colour-4} !important;
        --colour-text-tertiary: #{$mee-colour-4} !important;
        --colour-text-accent-primary: #{$mee-colour-1} !important;
        --colour-text-decoration: #{$ami-cool-grey} !important;
    }

    --colour-insight-background: hsl(218 85% 8%);

    main[data-site='materials-energy-expo'] {
        --colour-text-primary: #{$mee-colour-4};
    }

}

html, body, main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

body > main,
body > main > div {

    background: var(--colour-background-secondary);
    color: var(--colour-text-primary);
    --colour-link: var(--colour-text-accent-secondary);
    --colour-link-active: var(--colour-text-accent-primary);

    @media(min-width: 0px) {
        --page-padding-horizontal: var(--spacing-small);
    }

    @media(min-width: 600px) {
        --page-padding-horizontal: var(--spacing-medium);
    }

    @media(min-width: 1000px) {
        --page-padding-horizontal: var(--spacing-large);
    }

    @media(min-width: 1600px) {
        --page-padding-horizontal: 100px;
    }

    @media(min-width: 1800px) {
        --page-padding-horizontal: 200px;
    }

    @media(min-width: 2000px) {
        --page-padding-horizontal: 300px;
    }

    @media(min-width: 2200px) {
        --page-padding-horizontal: 400px;
    }

    @media(min-width: 2400px) {
        --page-padding-horizontal: 500px;
    }

    @media(min-width: 2600px) {
        --page-padding-horizontal: 600px;
    }

    @media(min-width: 2800px) {
        --page-padding-horizontal: 600px;
    }

    @media(min-width: 3000px) {
        --page-padding-horizontal: 700px;
    }

    @media(min-width: 3200px) {
        --page-padding-horizontal: 800px;
    }

    @media(min-width: 3400px) {
        --page-padding-horizontal: 1000px;
    }

    @media(min-width: 3600px) {
        --page-padding-horizontal: 1100px;
    }

    @media(min-width: 3800px) {
        --page-padding-horizontal: 1200px;
    }

    @media(min-width: 4000px) {
        --page-padding-horizontal: 1400px;
    }

    @media(min-width: 4200px) {
        --page-padding-horizontal: 1600px;
    }

    &[data-layout-test='true'] {

        &::before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            width: var(--page-padding-horizontal);
            z-index: 10000;
            background: hsla(0, 100%, 50%, 0.5);
        }

        &::after {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            width: var(--page-padding-horizontal);
            z-index: 10000;
            background: hsla(0, 100%, 50%, 0.5);
        }

    }

}

header:empty {
    display: none;
}

picture {
    display: flex;
}

picture,
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

picture.flipx,
video.flipx {
    transform: scalex(-1);
}

a picture {
    overflow: hidden;

    > img {
        transition: 0.2s ease-in-out;
    }

    ;
}

a div:has(video) {
    overflow: hidden;
}

a video {
    transition: 0.2s ease-in-out
}

a:hover picture > img,
a:hover video {
    transform: var(--transform-scale);
    [data-site='magazines'] & {
        transform: none;
    }
}

a[id]:not([href]):empty {
    position: absolute;
    top: -180px;
}

:root {
    @media(min-width: 0px) {
        --card-min-width: calc(100vw - 2 * var(--spacing-small));
    }

    @media(min-width: 302px) {
        --card-min-width: 250px;
    }

    --card-max-width: 500px;
    --transform-scale: scale(1.3);
}

select {
    cursor: pointer;
}

// InstantSearch

.ais-Hits--empty,
.ais-InfiniteHits--empty,
.ais-Hits.hidden,
.ais-InfiniteHits.hidden {
    max-height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    >* {
        display: none;
    }
}

.ais-InfiniteHits-item:empty {
    display: none;
}

.grecaptcha-badge {
    visibility: hidden;
}

//
// Filtering
//

*[data-visible='false'] {
    display: none;
}

[data-site='materials-energy-expo'] {
    article > div > *[data-background='MEE_Colour_5'] + *[data-background='MEE_Colour_5'] {
        padding-top: 0 !important;
    }
    article > div > *[data-background='MEE_Colour_4'] + *[data-background='MEE_Colour_4'] {
        padding-top: 0 !important;
    }
    article > div > *[data-background='MEE_Colour_3'] + *[data-background='MEE_Colour_3'] {
        padding-top: 0 !important;
    }
    article > div > *[data-background='MEE_Colour_2'] + *[data-background='MEE_Colour_2'] {
        padding-top: 0 !important;
    }
    article > div > *[data-background='MEE_Colour_1'] + *[data-background='MEE_Colour_1'] {
        padding-top: 0 !important;
    }
    article > div > *:not([data-background]) + *:not([data-background]) {
        padding-top: 0 !important;
    }

    article > div > *:first-child {
        border-top: 10px solid $mee-colour-1;
    }
}

section#ImageNonMobile{
    @media (max-width: 1300px) {
        display: none !important;   
    }
}

a[data-override='text'] {
    color: var(--colour-background-accent-secondary) !important;
    background: none !important;
    padding: 0 !important;
    margin-right: 0 !important;
    display: inline !important;
    font-size: inherit !important;
    border: none !important;
    :hover {
        color: var(--colour-link-active) !important;
    }
    [data-site='main'] & ,
    [data-site='magazines'] & {
        color: var(--colour-text-accent-primary) !important;
    }
}

a[data-override='button'] {
    [data-site='events'] & {
        @include events-button;
    }
}