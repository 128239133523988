:root {
    --layer-background: 0;
    --layer-body: 1;
    --layer-body-high: 2;
    --layer-submenu: 9;
    --layer-menu: 10;
    --layer-menu-controls: 12;
}

* { z-index: var(--layer-body); }
