:root {

    --spacing-small-quarter: 6px;
    --spacing-small-half: 13px;
    --spacing-small-three-quarters: 20px;
    --spacing-small: 26px;
    --spacing-medium-three-quarters: 39px;
    --spacing-medium: 52px;
    --spacing-large: 78px;

}
