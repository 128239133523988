:root {

    @media( min-width: 0px ) {
        --font-size-base: 14px;
    }
    @media ( min-width: 300px ) {
        --font-size-base: 16px
    }
    @media ( min-width: 450px ) {
        --font-size-base: 18px;
    }
    @media ( min-width: 900px ) {
        --font-size-base: 20px;
    }

    [data-site='main'] {
        --font-size-tiny: calc( 0.75 * var(--font-size-base) );
        --font-size-small: calc( 0.875 * var(--font-size-base) );
        --font-size-medium: var(--font-size-base);
        --font-size-large: calc( 1.2 * var(--font-size-base) );
        --font-size-header: calc( 1.75 * var(--font-size-base) );
        --font-size-featured: calc( 2 * var(--font-size-base) );
        --font-size-hero: calc( 2.6 * var(--font-size-base) );
    }

    [data-site='events'] {
        @media ( min-width: 0px ) {
            --font-size-base: 14px;
        }
        @media ( min-width: 350px ) {
            --font-size-base: 16px;
        }
        --font-size-tiny: calc( 0.75 * var(--font-size-base) );
        --font-size-small: calc( 0.875 * var(--font-size-base) );
        --font-size-medium: calc( var(--font-size-base) );
        --font-size-large: calc( 1.2 * var(--font-size-base) );
        --font-size-header: calc( 1.75 * var(--font-size-base) );
        --font-size-featured: calc( 2 * var(--font-size-base) );
        --font-size-hero: calc( 2.6 * var(--font-size-base) );
        @media( max-width: 400px ) {
            --font-size-hero: calc( 2.3 * var(--font-size-base) );
        }
    }

    [data-site='magazines'] {
        @media ( min-width: 0px ) {
            --font-size-base: 14px;
        }
        @media ( min-width: 350px ) {
            --font-size-base: 16px;
        }
        --font-size-tiny: calc( 0.75 * var(--font-size-base) );
        --font-size-small: calc( 0.875 * var(--font-size-base) );
        --font-size-medium: var(--font-size-base);
        --font-size-large: calc( 1.2 * var(--font-size-base) );
        --font-size-header: calc( 1.75 * var(--font-size-base) );
        --font-size-featured: calc( 2 * var(--font-size-base) );
        --font-size-hero: calc( 2.3 * var(--font-size-base) );
    }
}

@font-face {
    font-family: AvenirNextLTPro;
    font-weight: bold;
    src: url("/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: AvenirNextLTPro;
    font-weight: regular;
    src: url("/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
    font-display: swap;
}

body {
    font-family: AvenirNextLTPro, "Avenir Next", "Avenir", Helvetica, Arial, sans-serif;
    font-weight: regular;
    font-size: var(--font-size-medium);
    color: var(--colour-text-primary);
}

h6 {
    font-weight: normal;
}

h1 {
    font-size: var(--font-size-header);
    color: var(--colour-text-accent-primary);
    line-height: 1.2em;
    &:first-child { margin-top: 0; }
    a {
        color: var(--colour-text-accent-primary);
        &:hover { color: var(--colour-text-accent-secondary); }
    }
}

p {
    line-height: 1.6em;
    padding-bottom: 1em;
    [data-site='materials-energy-expo'] & {
        margin: 0;
    }
}

ul {
    line-height: 1.6em;
    > li > ul > li {
        list-style-type: disc !important;
    }
}

p + ul {
    [data-site='main'] & {
        margin-top: calc(-1 * var(--spacing-small));
    }
}

a {
    color: var(--colour-text-accent-secondary);
    text-decoration: none;
    transition: var(--transition-fast) color ease-in-out;
    &:hover {
        color: var(--colour-text-accent-primary);
    }
}
